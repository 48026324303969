import {
  Avatar,
  Box,
  Divider,
  ListItemAvatar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StockProduct } from "../../../entities/stock-entity";
import { useState } from "react";
import { FormattedNumber } from "react-intl";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useDarkMode } from "../../ThemeContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const styleDetail = {
  display: "grid",
  gridTemplateRows: "1fr",
  gridTemplateColumns: "auto 1fr",
  width: "100%",
  gridTemplateAreas: `"titleDetail content"`,
  gap: 1,
};

interface productProp {
  product: StockProduct;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const data = {
  labels: ["Ene", "Feb", "Mar", "Apr", "May"],
  datasets: [
    {
      label: "Stock",
      data: [400, 300, 200, 278, 189],
      fill: false,
      backgroundColor: "rgba(75,192,192,1)",
      borderColor: "rgba(75,192,192,1)",
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function ProductDetail({ product }: productProp) {
  const [value, setValue] = useState(0);
  const { isDarkMode } = useDarkMode();

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    minHeight: "550px",
    bgcolor: isDarkMode ? "#141A21" : "#FCF0F2",
    borderRadius: 2,
    boxShadow: 24,
    p: 2,
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={style}>
        <Box
          sx={{
            display: "grid",
            width: "100%",
            gridTemplateColumns: "auto 1fr",
            gridTemplateRows: "auto",
            gridTemplateAreas: `"imgProduct title"`,
            alignItems: "center",
            background: "red",
            borderRadius: "5px",
            p: 1,
            bgcolor: isDarkMode ? "#1C252E" : "white",
          }}
        >
          <Box sx={{ gridArea: "title" }}>
            <Typography variant="subtitle2">{product.title}</Typography>
            <Typography>Catidad: {product.count}</Typography>
          </Box>
          <ListItemAvatar sx={{ gridArea: "imgProduct" }}>
            <Avatar alt={product.title} src={product.image} />
          </ListItemAvatar>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Descripción general" {...a11yProps(0)} />
              <Tab label="Historial" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                display: "grid",
                width: "100%",
                gridTemplateColumns: "auto",
                gridTemplateRows: "auto auto auto",
                alignItems: "center",
                background: "red",
                borderRadius: "5px",
                p: 1,
                bgcolor: isDarkMode ? "#1C252E" : "white",
                marginTop: "20px",
              }}
            >
              <Box sx={styleDetail}>
                <Typography sx={{ gridArea: "titleDetail" }}>
                  Categoria:
                </Typography>
                <Typography variant="subtitle2" sx={{ gridArea: "content" }}>
                  {product.category}
                </Typography>
              </Box>
              <Box sx={styleDetail}>
                <Typography sx={{ gridArea: "titleDetail" }}>
                  Re-stock:
                </Typography>
                <Typography variant="subtitle2" sx={{ gridArea: "content" }}>
                  {product.id}
                </Typography>
              </Box>
              <Box sx={styleDetail}>
                <Typography sx={{ gridArea: "titleDetail" }}>
                  Codigo:
                </Typography>
                <Typography variant="subtitle2" sx={{ gridArea: "content" }}>
                  {product.barcode}
                </Typography>
              </Box>
              <Box sx={styleDetail}>
                <Typography sx={{ gridArea: "titleDetail" }}>
                  Precio de costo:
                </Typography>
                <Typography variant="subtitle2" sx={{ gridArea: "content" }}>
                  <FormattedNumber
                    value={product.sellingPrice}
                    style="currency"
                    currency="ARS"
                  />
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "5px",
                background: isDarkMode ? "#1C252E" : "white",
                marginTop: "10px",
                p: 1,
              }}
            >
              <Line data={data} options={options} />
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridTemplateRows: "auto auto",
                gridTemplateAreas: `"date  updateCount" "user stock"`,
                p: 1,
                bgcolor: isDarkMode ? "#1C252E" : "white",
                borderRadius: "5px",
                margin: "10px 0px 10px 0px",
              }}
            >
              <Typography sx={{ gridArea: "date" }} variant="subtitle2">
                23 jun 2024
              </Typography>
              <Typography sx={{ gridArea: "user" }} variant="subtitle2">
                Usuario
              </Typography>
              <Box
                sx={{
                  gridArea: "updateCount",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <KeyboardArrowUpIcon color="success" fontSize="small" />
                <Typography
                  sx={{ minWidth: "17px", textAlign: "right" }}
                  variant="subtitle2"
                >
                  2
                </Typography>
              </Box>
              <Typography
                sx={{ gridArea: "stock", textAlign: "right" }}
                variant="subtitle2"
              >
                Stock disponible: {product.count}
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridTemplateRows: "auto auto",
                gridTemplateAreas: `"date  updateCount" "user stock"`,
                p: 1,
                bgcolor: isDarkMode ? "#1C252E" : "white",
                borderRadius: "5px",
                margin: "10px 0px 10px 0px",
              }}
            >
              <Typography sx={{ gridArea: "date" }} variant="subtitle2">
                23 jun 2024
              </Typography>
              <Typography sx={{ gridArea: "user" }} variant="subtitle2">
                Usuario
              </Typography>
              <Box
                sx={{
                  gridArea: "updateCount",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <KeyboardArrowDownIcon sx={{ color: "red" }} fontSize="small" />
                <Typography
                  sx={{ minWidth: "17px", textAlign: "right" }}
                  variant="subtitle2"
                >
                  - 1
                </Typography>
              </Box>
              <Typography
                sx={{ gridArea: "stock", textAlign: "right" }}
                variant="subtitle2"
              >
                Stock disponible: {product.count}
              </Typography>
            </Box>
            <Divider />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
}
